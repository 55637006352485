import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/article/:id",
      name: "article",
      component: () => import("@/views/article/index"),
      meta: {
        title: "叙之",
      },
    },
    {
      path: "/user/:id",
      name: "user",
      component: () => import("@/views/user/index"),
      meta: {
        title: "叙之",
      },
    },
    {
      path: "/post/:id",
      name: "post",
      component: () => import("@/views/post/index"),
      meta: {
        title: "叙之",
      },
    },
    {
      path: "/theme/:id",
      name: "theme",
      component: () => import("@/views/theme/index"),
      meta: {
        title: "叙之",
      },
    },
  ],
});

// 设置title
router.afterEach((to, from) => {
  window.document.title = to.meta.title;
});

export default router;
