import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm') {
  return moment(dataStr).format(pattern)
})
Vue.filter('dateFormat', function (data) {
  if (data == null || data === '' || data === 0) {
    return ''
  }
  const date = new Date(data)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const now = new Date(Date.now())
  const remainSec = (Date.now() - data) / 1000
  // console.log('remainSec', remainSec)
  if (remainSec < 60) {
    return '刚刚'
  } else if (remainSec < 3600) {
    return Math.ceil(remainSec / 60) - 1 + '分钟前'
  } else if (remainSec < 3600 * 24) {
    return Math.ceil(remainSec / 3600) - 1 + '小时前'
  } else if (remainSec < 3600 * (now.getHours() + 24)) {
    return '昨天' + h + m
  } else {
    return Y + M + D + h + m
  }
})
